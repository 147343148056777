import React from 'react';
import Hero2 from '../components/Hero2';
import Section from '../components/Section';
import Section2 from '../components/Section2';
import BriefAboutUs from '../components/BriefAboutUs';
import ContactUsContainer from '../components/ContactUsContainer';

export default function Home() {

  return (
    <>
      <Hero2 />
      <Section />
      <Section2 />
      <BriefAboutUs />
      <ContactUsContainer />
    </>
  )
}