import React from 'react';
import {
    responsiveFontSizes, Grow, Container,
    ThemeProvider, Grid, Typography, Box, useTheme
} from '@mui/material';
import useStyles from '../styles/styles';
import Star from '../styles/icons';
import ContactModalButton from './ContactModalButton';
import box_diagram_pic from '../images/SiteFriendlyDiagram2.png';

const PeakPro = () => {
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const classes = useStyles();
    const sectionItems = [
        {
            id: 1,
            icon: <Box
                component="img"
                sx={{
                    width: "100%",
                }}
                src={box_diagram_pic}
            />,
            sentence1:
                '📡 Access historical and live data with web dashboards from anywhere: phone, PC, TV.',
            sentence2:
                '🔍 See the status of every machine, details for every cycle, history of fault codes, OEE, etc.',
            sentence3:
                '📣	Receive alerts instantly when important scenarios are detected.',
            sentence4:
                '📋 Schedule PDF reports periodically.',
            sentence5:
                '🔒 Data is securely gathered and stored automatically, with no need for human intervention.',
            sentence6:
                '🚀 Connect to any number of machines.',
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ mt: "10px", mb: "30px" }}>
                <Grid container className={classes.sectionGridTitleContainer}>
                    <Grow in={true} timeout={500} style={{ transformOrigin: '0 0 0' }}>
                        <Typography variant="h3" color={'secondary'} sx={{ textAlign: 'center', mt: "10px", mb: "30px" }}>
                            <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', color: 'secondary', textAlign: 'center', }} >
                                PeakPro</span> Technical Overview
                        </Typography>
                    </Grow>
                </Grid>
                <Grid container
                    sx={{
                        paddingTop: '20px',
                        paddingLeft: '15px',
                        // paddingRight: '15px',
                        paddingBottom: '20px',
                    }}
                    spacing={4}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item xs={12} md={4} sx={{
                        paddingTop: '10px',
                        // paddingLeft: '20px',
                        // paddingRight: '0px',
                        paddingBottom: '20px',
                    }}>
                        <Grid container maxWidth="lg"
                            sx={{
                                // paddingTop: '30px',
                                // paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '30px',
                                backgroundColor: '#ededed',
                                borderRadius: '30px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    color={'secondary'} sx={{ textAlign: 'center', mb: "5px", }}>
                                    <span style={{ display: 'inline-block', fontFamily: 'Manrope', letterSpacing: '-0.1rem', width: '75%' }}>Machine Compatibility</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    sx={{ ml: { xs: '65px', md: '0px' } }}
                                >
                                    <Star /> EthernetIP
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ ml: { xs: '65px', md: '0px' } }}>
                                    <Star /> Fanuc
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center', flexGrow: 1, display: { xs: 'block', md: 'none' } }}>
                                <Box sx={{ mb: '0px' }}>
                                    <ContactModalButton buttonText={'Schedule Your Free Demo'} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="lg"
                            sx={{
                                mt: "25px",
                                // paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '30px',
                                backgroundColor: '#000814',
                                borderRadius: '30px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                display: { xs: 'none', md: 'block' }
                            }}
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    color={'white'} sx={{ textAlign: 'center', mb: "5px", }}>
                                    <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>Want a test drive?</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ textAlign: 'center', mb: '0px' }}>
                                    <ContactModalButton buttonText={'Schedule Your Free Demo'} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        paddingTop: '10px',
                        // paddingLeft: '20px',
                        // paddingRight: '0px',
                        paddingBottom: '20px',
                    }}>
                        <Grid container maxWidth="lg"
                            sx={{
                                // paddingTop: '30px',
                                // paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '30px',
                                backgroundColor: '#ededed',
                                borderRadius: '30px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    color={'secondary'} sx={{ textAlign: 'center', mb: "5px", }}>
                                    <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>PeakPro Device<br />Tech Specs</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                // sx={{ mb: "5px", }}
                                >
                                    <Star /> DIN mountable
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                >
                                    <Star /> 12/24V power support
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                >
                                    <Star /> WiFi, Ethernet, 3G/4G capable
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                >
                                    <Star /> Fanless design
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                >
                                    <Star /> Multiple USB ports
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        paddingTop: '10px',
                        // paddingLeft: '20px',
                        // paddingRight: '0px',
                        paddingBottom: '20px',
                    }}>
                        <Grid container maxWidth="lg"
                            sx={{
                                // paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '30px',
                                backgroundColor: '#ededed',
                                borderRadius: '30px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    color={'secondary'} sx={{ textAlign: 'center', mb: "5px", }}>
                                    <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>PeakPro Software<br />Tech Specs</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                // sx={{ mb: "15px", }}
                                >
                                    <Star /> Installation webpage to configure, test, and troubleshoot

                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    <Star /> Manage account roles and permissions (admin, editor, viewer, etc.)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    <Star /> Years of data stored securely and available to backup or archive
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    <Star /> Always available and accessible everywhere
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    <Star /> One web interface for multiple machines
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.sectionGridContainer}>
                    {sectionItems.map((item) => (
                        <Grid
                            item
                            xs={12}
                            md={10}
                            minHeight={200}
                            key={item.id}
                            className={classes.sectionGridItem}
                            sx={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                        >
                            <Typography
                                variant="h4"
                                color={'secondary'} sx={{ textAlign: 'center', mb: "15px", }}>
                                <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>PeakPro Feature Summary</span>
                            </Typography>

                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence5}</Typography>
                            </Grow>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence6}</Typography>
                            </Grow>
                            <Grid item xs={12} sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
                                <Box sx={{ textAlign: 'center', mt: '5px', mb: '15px' }}>
                                    <ContactModalButton buttonText={'Schedule Your Free Demo'} />
                                </Box>
                            </Grid>
                            <Grid container justifyContent={'center'} style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', }}
                                sx={{ textAlign: 'center', mb: "45px", }}>
                                <Grow in={true} timeout={600} style={{ transformOrigin: '0 100 100' }}>
                                    {item.icon}
                                </Grow>
                            </Grid>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence1}</Typography>
                            </Grow>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence2}</Typography>
                            </Grow>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence3}</Typography>
                            </Grow>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h5" textAlign={'left'} className={classes.eventSentence}>{item.sentence4}</Typography>
                            </Grow>
                            {/* <Grid item xs={12} sx={{ textAlign: 'center', display: { xs: 'none', sm: 'block' } }}>
                                <Box sx={{ mt: '15px', mb: '15px' }}>
                                    <ContactModalButton buttonText={'Schedule Your Free Demo'} />
                                </Box>
                            </Grid> */}
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export default PeakPro;