import React from 'react';
import { Button } from '@mui/material';
import { animateScroll as scroll } from "react-scroll";
import getElmtPos from '../utils/helperFunctions';

const ContactUsButton = ({ displayText, onClick }) => {
  if (!onClick) {
    onClick = () => scroll.scrollTo(getElmtPos('#contact-us'))
  }
  
  return (
    <Button
      variant="contained"
      color="calltoaction"
      onClick={onClick}
      size='large'
      sx={{
        fontWeight: 700,
      }}
    >
      {displayText}
    </Button>
  )
}

export default ContactUsButton;