import React from 'react';
import Company from '../components/Company';
import BriefAboutUs from '../components/BriefAboutUs';
import ContactUsContainer from '../components/ContactUsContainer';


export default function CompanyPage() {
  return (
    <>
      <Company />
      <BriefAboutUs />
      <ContactUsContainer />
    </>
  )
}