import React from 'react';
import {
  responsiveFontSizes,
  ThemeProvider, Grid, Typography, Box, Container, useTheme
} from '@mui/material';
import Grow from '@mui/material/Grow';
import useStyles from '../styles/styles';
import ContactModalButton from './ContactModalButton';
import DemoContainer from './DemoContainer';
import PropTypes from 'prop-types';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Hero = () => {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();
  const demos = {
    peakpro:
      '<iframe width="100%" height="500px" scrolling="no" frameborder="yes" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d56942c1/website-snapshot-dashboard?orgId=1&refresh=1s&kiosk&output=embed"></iframe>'
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ mt: "10px", mb: "10px", paddingBottom: '20px', }}>
      <Grid container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
          alignItems="center">
          <Grid item sm={12} md={5}>
            <Grid spacing={0}>
              <Grow in={true} timeout={500} style={{ transformOrigin: '0 0 0' }}>
              <Grid item>
                <Typography
                    variant="h2"
                    color={'secondary'}
                  mt={0}
                  sx={{
                    fontFamily: 'Manrope',
                    // fontWeight: 700,
                    letterSpacing: '-0.1rem',
                  }}>
                    Solve the right manufacturing problems
                </Typography>
              </Grid>
            </Grow>
              <Grow in={true} timeout={600} style={{ transformOrigin: '100 0 0' }}>
              <Grid item>
                <Typography
                    variant="h5"
                  sx={{
                    // fontWeight: 100,
                    mt: '15px'
                  }}>
                    Accountable decision-making, less downtime, and a better bottom line made possible with
                    <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', }}> PeakPro.</span>
                </Typography>
              </Grid>
            </Grow>
              <Grow in={true} timeout={500} style={{ transformOrigin: '0 0 0' }}>
              <Grid item>
                  <Box sx={{ mt: '15px', mb: '15px' }}>
                    <ContactModalButton buttonText={'Schedule Your Free Demo'} />
                </Box>
              </Grid>
            </Grow>
          </Grid>
        </Grid>
          <Grid item sm={12} md={7} sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
            <Box >
              <Iframe iframe={'<iframe width="100%" height="325px" scrolling="no" frameborder="yes" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d5694214/website-snapshot-dashboard-2-mobile?orgId=1&refresh=1s&kiosk&output=embed"></iframe>'} />
            </Box>
          </Grid>
          <Grid item sm={12} md={7} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box >
              <Iframe iframe={'<iframe width="100%" height="405px" scrolling="no" frameborder="no" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d5694234/website-snapshot-dashboard-2?orgId=1&refresh=1s&kiosk&output=embed"></iframe>'} />
            </Box>
          </Grid>

      </Grid>
    </Container>
    </ThemeProvider>
  );
};

export default Hero;