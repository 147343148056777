import React, { useState } from 'react';
import ContactUsButton from './ContactUsButton';
import { IconButton, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContactUsContainer from './ContactUsContainer';

export default function ContactModalButton({ buttonText }) {
  const [open, setOpen] = useState(false);

  const onClickOpen = () => setOpen(true);
  const onClickClose = () => setOpen(false);

  return (
    <>
      <ContactUsButton displayText={buttonText} onClick={onClickOpen} />
      <Dialog open={open} onClose={onClickClose} >
        <IconButton
          aria-label="close"
          onClick={onClickClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px'
          }}
        >
          <CloseIcon />
        </IconButton>
        <ContactUsContainer />
      </Dialog>
    </>
  )
}