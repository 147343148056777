import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../styles/styles';
import ContactModalButton from './ContactModalButton';
import {ReactComponent as LogoSvg} from '../images/expeditionnimrod.svg';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, useTheme, responsiveFontSizes, } from '@mui/material';

const pages = [
  {
    text: 'PeakPro',
    path: 'peakpro'
  },
  {
    text: "FAQ",
    path: 'faq'
  },
  {
    text: "About Us",
    path: 'company'
  },
]

function ResponsiveAppBar() {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  let navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigatePage = (page) => {
    handleCloseNavMenu();
    navigate(page);
  }
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
    <AppBar position="static" color="primary" >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <SvgIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }}>
            <LogoSvg />
          </SvgIcon>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Manrope',
              // fontWeight: 900,
              letterSpacing: '-0.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Expedition Nimrod
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={() => navigatePage(page.path)}>
                  <Typography textAlign="center" variant='h5'>{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <SvgIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <LogoSvg />
          </SvgIcon>
          <Typography
              variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Manrope',
              // fontWeight: 700,
              letterSpacing: '-0.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Expedition Nimrod
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.map((page) => (
              <Button
                key={page.path}
                onClick={() => navigatePage(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
                size='medium'
              >
                {page.text}
              </Button>
            ))}
          </Box>
          <Box sx={{ ml: '10px', display: { xs: 'none', md: 'inline' } }}>
              <ContactModalButton buttonText={'Schedule Your Free Demo'} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
    </ThemeProvider>
  );
}
export default ResponsiveAppBar;