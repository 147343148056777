import React from "react";
import { Typography, Link } from "@mui/material";
import useStyles from '../styles/styles';


export default function BasicContactInfo() {
  const classes = useStyles();

  return (
    <>
      <Typography textAlign={'center'} >
        <Link href="mailto:contact@expeditionnimrod.com" className={classes.link} variant="h6" underline="none" textOverflow={'ellipsis'}>
          contact@expeditionnimrod.com
        </Link>
      </Typography>
      <br></br>
      <Typography textAlign={'center'} >
        <Link href="tel:3037209636" className={classes.link} variant="h6" underline="none" textOverflow={'ellipsis'}>
          (303)720-9636
        </Link>
      </Typography>
    </>
  )
}