import { createTheme, ThemeProvider } from '@mui/material/styles';
import Layout from "./containers/Layout"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./containers/Home"
import Company from "./containers/Company"
import PeakPro from './containers/PeakPro';
import FAQ from './containers/FAQ';

const theme = createTheme({
  typography: {
    fontFamily: [
      'D-DIN',
      'monospace',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'TekturSemiCondensedExtraBold',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#000814',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // main: '#fb5607',
      main: '#003566',
      light: '#ffd60a',
      // dark: '#fb5607',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#121212',
    },
    calltoaction: {
      // main: '#ffd60a',
      // main: '#FDC921',
      main: '#FFD60A',
      contrastText: '#121212',
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="company" element={<Company />}/>
            <Route path="peakpro" element={<PeakPro />} />
            <Route path="faq" element={<FAQ />} />
            {/* Add additional routes here (update the 'pages' object in Header2) */}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;