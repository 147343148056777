import React, { useState } from "react";
import { Typography, Box, ThemeProvider, Grid, Container, responsiveFontSizes, useTheme } from "@mui/material";
import BasicContactInfo from "./BasicContactInfo";
import ContactForm from "./ContactForm";
import useStyles from '../styles/styles';
import classes from './BriefAboutUs.css';


export default function ContactUsContainer() {

  let theme = useTheme();
  theme = responsiveFontSizes(theme);

  const [isMailServerRunning, setIsMailServerRunning] = useState(true);
  
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg"
        sx={{
          // mt: "10px",
          // background: "linear-gradient(180deg, rgba(256,214,10,0.5),transparent)"
        }}
      // class="gradient-background"
      >
    <Box
      className={classes.formContainer}
      id="contact-us"
      name="contact-us"
    >
          <Grid container className={classes.sectionGridTitleContainer} >
            <Typography variant="h3" color={'secondary'} sx={{ textAlign: 'center', mt: "10px", mb: "30px" }}>
              Contact  <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>Expedition Nimrod</span></Typography>
          </Grid>
        <br></br>
        {
          isMailServerRunning
          ? <ContactForm />
          : <BasicContactInfo />
        }

        </Box>
      </Container>
    </ThemeProvider>
  )
}