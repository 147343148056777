import React from "react";
import Header2 from "../components/Header2";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import useStyles from '../styles/styles';

function Layout() {
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <Header2 />
      <div className={classes.layoutContent}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default Layout;