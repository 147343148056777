import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../styles/styles';

const Footer = () => {
  const date = new Date().getFullYear();
  const classes = useStyles();

  return (
    <Box className={classes.footerContainer}>
      <Typography className={classes.footerDate}>&copy; PeakPro {date}</Typography>
    </Box>
  );
};

export default Footer;