import React from 'react';
import FAQ from '../components/FAQ';
import ContactUsContainer from '../components/ContactUsContainer';


export default function FAQPage() {
    return (
        <>
            <FAQ />
            <ContactUsContainer />
        </>
    )
}