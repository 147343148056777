import React, { useState } from 'react';
import axios from 'axios';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Link,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import useStyles from '../styles/styles';
import { useTheme } from '@mui/material/styles';

export default function ContactForm() {

  const [formValues, setFormValues] = useState({
    name: {
      value: '',
      error: false,
      errorMessage: 'Please include your name',
      isOptional: false,
    },
    company: {
      value: '',
      error: false,
      errorMessage: '',
      isOptional: true,
    },
    email: {
      value: '',
      error: false,
      errorMessage: 'Please include your email address',
      isOptional: false,
    },
    message: {
      value: '',
      error: false,
      errorMessage: 'Please include a message',
      isOptional: false,
    }
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    });
  }

  const sendForm = async () => {
    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key].value);
    };
    formData.append('_captcha', false);

    const url = 'https://formsubmit.co/3d02f40d745a7fd74d21ee51f7f451e1';
    const res = await fetch(
      url,
      {
        method: 'POST',
        body: formData
      }
    )
    console.log(res);

    return false
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    let error = false;

    const formValuesDup = { ...formValues };
    for (const formField of Object.keys(formValuesDup)) {
      if (formValuesDup[formField].isOptional) {
        continue;
      }
      const value = formValuesDup[formField].value;
      formValuesDup[formField] = {
        ...formValuesDup[formField],
        error: !value
      }
      if (formValuesDup[formField].error) error = true;
    }
    setFormValues(formValuesDup);

    if (!error) {
      sendForm();
      setFormSubmitted(true);
    }
  };

  return (
    <>
      {!formSubmitted
        ? (
          <Box
            noValidate
            autoComplete="off"
          >
            <form onSubmit={onSubmitForm}>
              {formValues.name.error
                ? <Typography color={theme.palette.error.main}>{formValues.name.errorMessage}</Typography>
                : <></>
              }
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                required
                error={formValues.name.error}
                className={classes.inputField}
                value={formValues.name.value}
                onChange={handleChange}
              />

              {formValues.company.error
                ? <Typography color={theme.palette.error.main}>{formValues.company.errorMessage}</Typography>
                : <></>
              }
              <TextField
                label="Company"
                name="company"
                variant="outlined"
                fullWidth
                error={formValues.company.error}
                className={classes.inputField}
                value={formValues.company.value}
                onChange={handleChange}
              />

              {formValues.email.error
                ? <Typography color={theme.palette.error.main}>{formValues.email.errorMessage}</Typography>
                : <></>
              }
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                required
                error={formValues.email.error}
                className={classes.inputField}
                value={formValues.email.value}
                onChange={handleChange}
              />

              {formValues.message.error
                ? <Typography color={theme.palette.error.main}>{formValues.message.errorMessage}</Typography>
                : <></>
              }
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                placeholder="Enter a message"
                fullWidth
                required
                multiline
                rows={6}
                error={formValues.message.error}
                className={classes.inputField}
                value={formValues.message.value}
                onChange={handleChange}
              />

              <Button
                variant="contained"
                type="submit"
                color="calltoaction"
                sx={{ width: '200px', fontSize: '16px', fontWeight: '700' }}
              >
                Send Message
              </Button>
            </form>
            <Typography textAlign={'center'} sx={{ marginTop: '30px' }} >
              Or email us directly!{' '}
              <Link href="mailto:contact@expeditionnimrod.com" className={classes.link} underline="none" textOverflow={'ellipsis'}>
                contact@expeditionnimrod.com
              </Link>
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="h5"
            align="center"
          >
            Your message has been sent. Thank you!
          </Typography>
        )
      }
    </>
  )
}