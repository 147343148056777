import React from 'react';
import {
    responsiveFontSizes, Grow, Container,
    ThemeProvider, Grid, Typography, Box, useTheme, Divider
} from '@mui/material';
import useStyles from '../styles/styles';
import ContactUsButton from './ContactUsButton';
import { ReactComponent as Diagram } from '../images/expeditionnimrod2.svg';
import mountain_pic from '../images/mountain-pic.jpg';
import { Link } from 'react-scroll';



const FAQ = () => {
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const classes = useStyles();
    const sectionItems = [
        {
            question: "What does Expedition Nimrod mean?",
            answer: "We are named after the Nimrod Expedition that Ernest Shackleton led to the Antarctic from 1907-1909.",
        },
        {
            question: "Where is your company located?",
            answer: "We are based in East Moline of the Quad Cities.",
        },
        {
            question: "What is the price of PeakPro?",
            answer: "We determine pricing on a per-client basis. Please contact us to request a quote.",
        },
        {
            question: "Do I have to use the cloud?",
            answer: "No. PeakPro is configurable to run entirely on-premise while still delivering all the same great features.",
        },
    ]
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ mt: "10px", mb: "30px" }}>
                <Grid container className={classes.sectionGridTitleContainer}>
                    <Grow in={true} timeout={500} style={{ transformOrigin: '0 0 0' }}>
                        <Typography variant="h3" color={'secondary'} sx={{ mt: "10px", mb: "30px" }}>
                            Frequently Asked  <span
                                style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', }} c
                                lassName={classes.sectionTitle}>
                                Questions
                            </span></Typography>
                    </Grow>
                </Grid>
                <Grid container className={classes.sectionGridContainer}>
                    {sectionItems.map((item) => (
                        <Grid
                            item
                            xs={12}
                            md={9}
                            // minHeight={200}
                            className={classes.faqGridItem}
                        >
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography
                                    variant="h5"
                                    color={'secondary'}
                                    textAlign={'left'}
                                    className={classes.eventSentence}
                                    style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', color: 'secondary' }}
                                >
                                    {item.question}
                                </Typography>
                            </Grow>
                            <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                                <Typography variant="h6" textAlign={'left'} className={classes.eventSentence}>{item.answer}</Typography>
                            </Grow>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ThemeProvider >
    )
}

export default FAQ;