import React from 'react';
import PeakPro from '../components/PeakPro';
import ContactUsContainer from '../components/ContactUsContainer';

export default function PeakProPage() {

    return (
        <>
            <PeakPro />
            <ContactUsContainer />
        </>
    )
}