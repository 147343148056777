import { makeStyles } from '@mui/styles';

const styles = (theme) => {
  return {
    toolBar: {
      // height: '12vh',
      // display: 'flex',
      // flexDirection: 'column',
      // flexWrap: 'wrap',
      // alignContent: 'center',
      // alignItems: 'center',
      // justifyContent: 'center',
      // padding: '20px',
      backgroundColor: 'white',
    },
    toolBarGrid: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    toolBarGrid2: {
      // padding: '20px',
      display: 'flex',
      // flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    logo: {
      color: 'black',
      cursor: 'pointer',
    },
    logoimage: {
      width: '60px',
      height: '60px',
      padding: '0',
      margin: '0',
    },
    link: {
      color: "#000",
      cursor: 'pointer',
      '&:hover': {
        background: 'linear-gradient(89.33deg, #21B9CC 14.2%, #0092D2 86.86%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
    },
    menuIcon: {
      color: '#000',
    },
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit'
    },
    iconRoot: {
      textAlign: 'center'
    },
    formContainer: {
      flexGrow: 1,
      // padding: '10px',
      maxWidth: '700px',
      margin: '30px auto',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
      },
    },
    form: {
      marginTop: '30px',
    },
    formHeading: {
      textAlign: 'center',
    },
    heroBox: {
      width: '100%',
      display: 'flex',
      minHeight: '500px',
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor: 'rgb(0, 16, 38)',
      // backgroundImage: `linear-gradient(rgba(13, 40, 93, 1), rgba(13, 40, 93, 0.3))`,
      // backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(${bgimg})`,
      // backgroundColor: 'rgb(13, 40, 93)',
      // backgroundColor: 'rgba(13, 40, 93, 0)',
      // backgroundColor: 'radial-gradient(ellipse at center, rgba(13, 40, 93, 0) 0%,rgba(13, 40, 93, 1) 70%,rgba(13, 40, 93, 1) 100%)',
      // backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${weldimage})`,
      // color: 'white',
    },
    gridContainer: {
      // display: 'flex',
      // direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      // flex: 'flex-basis',
      maxWidth: '1300px',
      // justifyContent: 'flex-start',
      padding: '15px',
    },
    gridContainer2: {
      display: 'flex',
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      // flex: 'flex-basis',
      maxWidth: '1300px',
      // justifyContent: 'flex-start',
      padding: '50px',
    },
    aboutUsContainer: {
      paddingBottom: '30px',
    },
    aboutUsSubtitle: {
      opacity: '0.7',
      paddingBottom: '30px',
      fontSize: '18px',
    },
    heroTitle: {
      color: 'white',
      fontWeight: '700',
      paddingBottom: '15px',
      background: 'linear-gradient(89.33deg, #21B9CC 14.2%, #0092D2 86.86%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    heroItalicTitle: {
      fontStyle: 'italic',
      fontWeight: '600',
    },
    title: {
      color: 'black',
      paddingBottom: '15px',
    },
    subtitle: {
      // opacity: '0.4',
      // color: 'rgb(200, 200, 200)',
      color: 'white',
      paddingBottom: '30px',
    },
    largeImage: {
      width: '100%',
    },
    heroImage: {
      width: '100%',
      borderRadius: "25% 10%",
      minheight: '600px',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      // minheight: '100%',
    },
    sectionGridTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      // minHeight: '100px',
      // paddingTop: '30px',
      // paddingLeft: '30px',
      flexWrap: 'wrap'
    },
    sectionTitle: {
      color: 'secondary',
      // paddingBottom: '15px',
      // background: 'linear-gradient(89.33deg, #21B9CC 14.2%, #0092D2 86.86%)',
      // '-webkit-background-clip': 'text',
      // '-webkit-text-fill-color': 'transparent',
    },
    missionStatementText: {
      color: 'white',
      fontWeight: 'bold',
      // background: 'rgb(0, 16, 38),',
      // backgroundImage: `linear-gradient(rgba(13, 40, 93, 1), rgba(13, 40, 93, 0.3))`,
      backgroundColor: 'rgba(13, 40, 93, 1)',
      // paddingBottom: '15px',
      // background: 'linear-gradient(89.33deg, #21B9CC 14.2%, #0092D2 86.86%)',
      // '-webkit-background-clip': 'text',
      // '-webkit-text-fill-color': 'transparent',
    },
    sectionGridContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '200px',
    },
    sectionGridItem: {
      backgroundColor: '#ededed',
      // textAlign: 'left',
      paddingTop: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '30px',
      // width: '200px',
      borderRadius: '30px',
      // margin: '10px !important',
    },
    faqGridItem: {
      backgroundColor: '#ededed',
      // textAlign: 'left',
      padding: '30px',
      // width: '200px',
      borderRadius: '30px',
      margin: '10px !important',
    },
    eventSentence: {
      paddingBottom: '10px',
    },
    inputField: {
      marginBottom: '20px !important',
    },
    textArea: {
      width: '100%',
      marginBottom: '20px',
      fontSize: '16px',
      padding: '10px',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      miHeight: '10vh',
      padding: '20px',
      justifyContent: 'center',
      backgroundColor: '#f2f0f1',
      flexDirection: 'column',
    },
    footerText: {
      paddingBottom: '10px',
    },
    footerDate: {
      opacity: '0.4',
    },
    testimonialCard: {
      backgroundColor: '#fff',
      padding: '10px',
      minHeight: '200px',
      display: 'flex',
      alignItems: 'center',
    },
    testimonialStatement: {
      paddingBottom: '25px',
    },
    avatar: {
      marginRight: '10px',
    },
    testimonialPosition: {
      fontSize: '14px',
      opacity: '0.6',
    },
    layout: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    layoutContent: {
      flexGrow: 1,
    }
  };
};

const useStyles = makeStyles(styles);
export default useStyles;